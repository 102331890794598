import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import BASEURL from "../../Config/global";
import MainLayout from "../../Layout/MainLayout";

import "./style.css";
import CustomButton from "../../Components/CustomButton";
import { Modal } from "react-bootstrap";
import CustomTable from "../../Components/CustomTable";
import { avatar } from "../../Assets/images";
import { CrossIcon, PencilEdit, SaveShareMixEmailIcon, SaveShareMixLinkIcon, SaveShareMixWhatsappIcon, crossIcon } from "../../Assets/svg";
import { EmailIcon, EmailShareButton, FacebookMessengerIcon, FacebookMessengerShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";

import CryptoJS from 'crypto-js';
import { userLogout } from "../../Store/Slices/APIs";
import { useDispatch, useSelector } from "react-redux";
import BASEURLFrontend from "../../Config/urls";
import { Link } from "react-router-dom";
import LinkCopiedModal from "../../Components/LinkCopiedModal";
import HouseHoldInviteModal from "../../Components/HouseHoldInviteModal";

// import { FillPlay, FillPause, TrackedPlay } from '../Assets/svg';

const HouseholdMember = () => {

  const emailRef = useRef('');
  const dispatch = useDispatch();
  const userPrompts = useSelector((state) => state.user.userPrompts);
  const [data, setData] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const [removeModalId, setRemoveModalId] = useState(null);
  const [succesModal, setSuccesModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [copiedText, setCopiedText] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [houseHoldEmailModal, setHouseHoldEmailModal] = useState(false);
  const [houseHoldEmailMsg, setHouseHoldEmailMsg] = useState({ error: false, text: '', type: '' });
  const [houseHoldSuccesModal, setHouseHoldSuccesModal] = useState(false);

  const headers = [
    // {
    //   key: "image",
    //   title: "Image",
    // },
    {
      key: "FirstName",
      title: "First Name",
    },
    {
      key: "LastName",
      title: "Last Name",
    },
    {
      key: "email",
      title: "Email",
    },
    {
      key: "remove",
      title: "Remove",
    },
  ];

  useEffect(() => {
    dispatch(userLogout());
    fetchData();
  }, [])

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    encryptText(currentUser.id);
  }, []);

  const encryptText = (no) => {
    const key = 'secret-key'; // Replace with your secret key
    const id = no.toString()
    const encrypted = CryptoJS.AES.encrypt(id, key);
    const encryptedText = encrypted?.toString()?.replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');
    setShareUrl(`${BASEURLFrontend}/signup?id=${encryptedText}`);
  };

  const fetchData = async () => {
    const id = localStorage.getItem("userId");
    try {
      const response = await axios.get(`${BASEURL}/api/user/household/${id}`);
      if (response.data.error != true) {
        setData(response.data.data);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteHouseHoldMember = async (id) => {
    setRemoveModal(true);
    try {
      const response = await axios.delete(
        `${BASEURL}/api/user/remove_household/${id}`
      );
      if (response.data.error != true) {
        setRemoveModal(false);
        setSuccesModal(true);
        setTimeout(() => {
          setSuccesModal(false);
          fetchData();
        }, 2500);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const copyURL = () => {
    // console.log("---")
    var textField = document.createElement('textarea')
    textField.innerText = shareUrl
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    setCopiedText(true);
    textField.remove();
    setTimeout(() => {
      setCopiedText(false);
    }, 1000);
  }

  const houseHoldShare = async () => {
    setHouseHoldEmailMsg({ error: false, text: '' })
    if (emailRef.current.value.length > 0) {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (pattern.test(emailRef.current.value)) {
        const currentUser = JSON.parse(localStorage.getItem("user"));
        const formDataToSend = new FormData();
        formDataToSend.append("email", emailRef.current.value);
        formDataToSend.append("link", shareUrl);
        formDataToSend.append("user_id", currentUser?.id);
        try {
          const response = await axios.post(
            `${BASEURL}/api/user/send_email_household/`,
            formDataToSend
          );
          if (!response.data.error) {
            setHouseHoldSuccesModal(true);
            setHouseHoldEmailModal(false);
            setTimeout(() => {
              setHouseHoldSuccesModal(false);
            }, 4000);
          } else {
            setHouseHoldEmailMsg({ error: true, text: 'Please Enter a valid Email' })
          }
        } catch (error) {
          console.log(error)
        }
      } else {
        setHouseHoldEmailMsg({ error: true, text: 'Please Enter a valid Email' })
      }
    } else {
      setHouseHoldEmailMsg({ error: true, text: 'Please Enter Your Email' })
    }
  }

  return (
    <>
      <MainLayout title="Household Members" arrow backtoaccount>
        <div className="row">
          <div className="col-md-12">

            <div className="mb-4 mt-3 mt-lg-0">
              <Link to={""} className="text-decoration-none fw-light py-2 px-2 d-flex align-items-center" onClick={() => setShowModal(true)}>
                <svg width={'20px'} className="me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6zm3.519 0L12 11.671 18.481 6H5.52zM20 7.329l-7.341 6.424a1 1 0 0 1-1.318 0L4 7.329V18h16V7.329z" fill="#ffffff" />
                </svg>
                Invite Household Member
              </Link>
              {/* <CustomButton
                variant="primaryButton"
                text="Invite Household Member"
                onClick={() => setShowModal(true)}
              /> */}
            </div>

            <CustomTable headers={headers}>
              <tbody>
                {data &&
                  data.map((item) => (
                    <tr>
                      {/* <td>
                        <img
                          src={
                            item?.user_image.includes("/no_image.png")
                              ? avatar
                              : item?.user_image
                          }
                          alt=""
                          className="profileImage household-member-image"
                        />
                      </td> */}
                      <td>{item?.first_name}</td>
                      <td> {item?.last_name}</td>
                      <td> {item?.email}</td>
                      <td className="household-member-remove-btn">
                        <CustomButton
                          variant="primaryButton"
                          text="Remove"
                          onClick={() => {
                            setRemoveModal(true);
                            setRemoveModalId(item?.id);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </CustomTable>
          </div>
        </div>
      </MainLayout>

      {/* logout modal */}
      <Modal
        show={removeModal}
        centered
        className="soundModal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-end">
            <button
              className="closeButton notButton ms-auto"
              onClick={() => setRemoveModal(false)}
            >
              <img src={crossIcon} alt="" />
            </button>
          </div>
          <div className="customModalContent my-5">
            <h2 className="font-16 lh-16 fw-normal mb-3 text-center">
              {userPrompts?.household_remove_message}
            </h2>
            <div className="mt-4 d-flex align-items-center justify-content-center">
              <button
                style={{ backgroundColor: "#ffffff1f" }}
                className="mx-3 px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0"
                onClick={() => {
                  deleteHouseHoldMember(removeModalId);
                }}
              >
                <span className="">Yes</span>
              </button>
              <button
                style={{ backgroundColor: "#ffffff1f" }}
                className="mx-3 px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0"
                onClick={() => setRemoveModal(false)}
              >
                <span className="">No</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* success modal */}
      <Modal
        show={succesModal}
        centered
        className="success-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="my-5">
            <h2 className="font-16 lh-16 fw-normal my-5 text-center">{userPrompts?.household_remove_notification}</h2>
          </div>
        </Modal.Body>
      </Modal>

      {/* Invite Household Modal */}
      <Modal
        show={showModal}
        centered
        className="soundModal mixer-modal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="share-mix-wrapper d-flex flex-column">
            <div className="save-mix-header text-end">
              <button
                className="notButton ms-auto d-flex align-items-center p-4"
                onClick={() => setShowModal(false)}

              >
                <img src={crossIcon} alt="Close Button" className="img-fluid" style={{ width: '15px' }} />
              </button>
            </div>
            <div className="d-flex flex-column justify-content-between flex-grow-1">
              <div className="flex-shrink-0 mb-4">
                <h5 className="text-center fs-3">Invite Household Member</h5>
              </div>
              <div className="save-share-icon-wrapper flex-shrink-0 mb-5">
                <div className="d-flex align-items-center justify-content-center mb-3">
                  <div className="whatsapp-icon position-relative mx-1">
                    <WhatsappShareButton
                      url={shareUrl}
                      quote={'Title or jo bhi aapko likhna ho'}
                      hashtag={'#portfolio...'}
                      beforeOnClick={() => setShowModal(false)}
                    >
                      <WhatsappIcon size={50} round={true} />
                    </WhatsappShareButton>
                    <button
                      className="notButton"
                    >
                      <img src={SaveShareMixWhatsappIcon} alt="Close Button" style={{ width: '50px' }} />
                    </button>
                  </div>
                  <div className="messenger-icon position-relative mx-1">
                    {/* <EmailShareButton
                      url={shareUrl}
                      quote={'Title or jo bhi aapko likhna ho'}
                      hashtag={'#portfolio...'}
                      beforeOnClick={() => setShowModal(false)}
                    >
                      <EmailIcon size={50} round={true} />
                    </EmailShareButton> */}
                    <button className="notButton" onClick={() => { setHouseHoldEmailModal(true); setShowModal(false); }}>
                      <img src={SaveShareMixEmailIcon} alt="Close Button" style={{ width: '50px' }} />
                    </button>
                  </div>
                  <div className="copy-link-icon position-relative mx-1">
                    <button
                      className="notButton "
                      onClick={copyURL}
                    >
                      <img src={SaveShareMixLinkIcon} alt="Close Button" style={{ width: '50px' }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Copied Modal */}
      <LinkCopiedModal copiedText={copiedText} />


      {/* Email Modal */}
      <Modal
        show={houseHoldEmailModal}
        centered
        className="soundModal"
        backdrop="static"
      >
        <Modal.Body>
          <div className="text-end">
            <button
              className="closeButton notButton ms-auto"
              onClick={() => { setHouseHoldEmailModal(false); }}
            >
              {/* <CrossIcon /> */}
              <img src={crossIcon} alt="" />
            </button>
          </div>
          <div className="customModalContent mt-4">
            <h2 className="fs-6 fw-normal mb-3 ps-3">Email</h2>
            <div>
              <input type="email" required ref={emailRef} className="searchBar w-100" />
              {houseHoldEmailMsg.error && <small className="text-danger">{houseHoldEmailMsg.text}</small>}
            </div>
            <div className="mt-4 d-flex gap-3 justify-content-center mb-4">
              <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={houseHoldShare}>
                <span className=''>Send</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Invite Sent Modal */}
      <HouseHoldInviteModal houseHoldSuccesModal={houseHoldSuccesModal} />



    </>
  );
};

export default HouseholdMember;
