import { useEffect, useRef, useState } from "react";
import CustomButton from "../CustomButton";
import axios from "axios";
import Loader from "../Loader";
import CustomInput from "../CustomInput";
import DateField from "../DateField";
import BASEURL from "../../Config/global";
import BASEURLFrontend from "../../Config/urls";
import { Modal } from "react-bootstrap";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import { crossIcon, SaveShareMixEmailIcon, SaveShareMixLinkIcon, SaveShareMixWhatsappIcon } from "../../Assets/svg";
import { Link } from "react-router-dom";
import "./style.css";
import { formatPhoneNumber, validateBirthDate } from "../../Services/Common";
import PDF from "../../Assets/pdf/terms-of-use.pdf";
import LinkCopiedModal from "../LinkCopiedModal";
import { useSelector } from "react-redux";


const DescriptionEnjoyAndEarn = ({ stepBack, stepNext }) => {

    return (
        <>
            <div className="earn-description-wrapper p-2 p-lg-5 pb-lg-0">

                <div className="text-center mt-4 mt-lg-0">
                    <h4>Earn by Sharing Our Relaxation Secret!</h4>
                </div>

                <div className="text-start w-100 earn-points my-5" style={{ maxWidth: '350px' }}>
                    <p className="mb-0">Enjoy our relaxation and sleep tips?</p>
                    <p className="mb-0">Share them with your friends and make money!</p>
                    <p className="my-4">Just use your special referral link to tell others about our website.</p>
                    <p className="mb-0">For every new visitor who signs up through your link, you'll earn a commission.</p>
                    <p className="mb-0">It’s simple: Spread the word, help others relax, and get rewarded!</p>
                </div>

                <div className="d-lg-flex align-items-center footer">
                    <div style={{ flex: '0 0 350px' }}>
                        <p>Start sharing your link now and see how much you can earn.</p>
                    </div>
                    <div className="flex-grow-1 text-lg-center continue-btn ps-lg-5 ms-lg-2 mb-4 mb-lg-0">
                        <CustomButton
                            variant="primaryButton"
                            text="Continue"
                            type="button"
                            onClick={stepNext}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

const LegalForm = ({ stepBack, stepNext, submit }) => {
    const userPrompts = useSelector((state) => state.user.userPrompts);

    const [formData, setFormData] = useState({
        terms_and_conditions: false,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ error: false, text: '' });
    const user = JSON.parse(localStorage.getItem("user"));
    const [termOfUseModal, setTermOfUseModal] = useState(false);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setError({ error: false, text: "" });
        if (name === 'legal_phone_number') {
            const phoneNumber = formatPhoneNumber(value);
            setFormData({ ...formData, [name]: phoneNumber });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const submitLegalForm = async (e) => {
        try {
            e.preventDefault();
            setLoading(true);
            setError({ error: false, text: "" });
            if (validateBirthDate(formData.legal_date_of_birth) && formData.terms_and_conditions) {
                const today = new Date();
                const givenDate = new Date(formData.legal_date_of_birth);
                const minDate = new Date("01/01/1800");

                if (givenDate > today || minDate > givenDate) {
                    setLoading(false);
                    setError({
                        error: true,
                        text: "Please enter valid Birthdate.",
                    });
                    return
                } 
                const date = formData.legal_date_of_birth.split('/')[2] + '-' + formData.legal_date_of_birth.split('/')[1] + '-' + formData.legal_date_of_birth.split('/')[0]
                const obj = {
                    legal_first_name: formData.legal_first_name,
                    legal_last_name: formData.legal_last_name,
                    legal_date_of_birth: formData.legal_date_of_birth,
                    legal_phone_number: formData.legal_phone_number,
                    legal_email: formData.legal_email,
                    legal_address: formData.legal_address,
                }
                const response = await axios.post(`${BASEURL}/api/user/legal_details_and_propagation_code/${user.id}/`, obj);
                if (!response.data.error) {
                    setLoading(false);
                    submit();
                } else {
                    setLoading(false);
                }
            } else {
                const showAgreementMessage = userPrompts?.financial_profile_agreement_message || "Please agree to the Terms of Use to complete your submission";
                const showInvalidBirthdateMessage = userPrompts?.birth_day_validate || "Invalid date format. Please enter a valid date in mm/dd/yyyy format.";

                setError({
                    error: true,
                    text: !formData.terms_and_conditions ?  showAgreementMessage : showInvalidBirthdateMessage,
                });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }

    }

    const handleCheckbox = () => {
        setError({ error: false, text: "" });
        setFormData({ ...formData, terms_and_conditions: !formData.terms_and_conditions });
    }

    return (
        <>
            <div className="p-lg-5 p-2 legal-form-area">
                <h5 className="text-center fs-3 pb-lg-3 mt-4 mt-lg-0">Financial Profile</h5>
                <form onSubmit={submitLegalForm} className="w-100 mt-5">
                    <div className="row">
                        <div className="col-lg-4">
                            <CustomInput
                                label="First Name"
                                required
                                id="fname"
                                type="text"
                                labelClass="mainLabel"
                                inputClass="mainInput"
                                name="legal_first_name"
                                value={formData.legal_first_name || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-4">
                            <CustomInput
                                label="Last Name"
                                required
                                id="lname"
                                type="text"
                                labelClass="mainLabel"
                                inputClass="mainInput"
                                name="legal_last_name"
                                value={formData.legal_last_name || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-4">
                            <DateField
                                label={'Birthdate'}
                                formValue={formData.legal_date_of_birth || ""}
                                selectedValue={(value) => {
                                    setFormData({ ...formData, ['legal_date_of_birth']: value })
                                }}
                            />
                        </div>
                        <div className="col-lg-6">
                            <CustomInput
                                label="Email"
                                required
                                id="email"
                                type="email"
                                labelClass="mainLabel"
                                inputClass="mainInput"
                                name="legal_email"
                                value={formData.legal_email || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-6">
                            <CustomInput
                                label="Phone No"
                                required
                                id="phone_no"
                                type="text"
                                labelClass="mainLabel"
                                inputClass="mainInput"
                                name="legal_phone_number"
                                value={formData.legal_phone_number || ""}
                                onChange={handleChange}
                                maxLength={14}
                            />
                        </div>
                        <div className="col-lg-12">
                            <CustomInput
                                label="Address"
                                required
                                id="address"
                                type="text"
                                labelClass="mainLabel"
                                inputClass="mainInput"
                                name="legal_address"
                                value={formData.legal_address || ""}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="col-lg-12">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center custom-checkbox">
                                    <input
                                        type="checkbox"
                                        name="featured"
                                        id="featured"
                                        onChange={handleCheckbox}
                                    />
                                    <label htmlFor="featured" className="mainLabel ms-0">
                                        <span className="ms-3 " 
                                        onClick={(event) => {
                                            event.stopPropagation()
                                            event.preventDefault()
                                            // setTermOfUseModal(true)
                                        }}>
                                            Click here to agree to the <span style={{ cursor: "pointer", textDecoration: 'underline' }}
                                                onClick={(event) => {
                                                    event.stopPropagation()
                                                    event.preventDefault()
                                                    setTermOfUseModal(true)
                                                }}
                                            >Terms of Use.</span>
                                        </span>
                                    </label>
                                </div>


                                <div>
                                    {loading ? <Loader /> : <CustomButton variant="primaryButton" text="Submit" />}
                                </div>
                            </div>
                            {error.error && (
                                <p className="smallText lightColor mt-3">{error.text}</p>
                            )}
                        </div>
                    </div>
                </form>
            </div>
            {/* Term Of Use */}
            <TermOfUseModal show={termOfUseModal} close={() => setTermOfUseModal(false)} />
        </>
    )
}

const ShareToSocialPlatform = ({ url, showPlatforms }) => {
    const emailRef = useRef('')
    const [InviteEmailError, setInviteEmailError] = useState({ error: false, text: '', type: '' });
    const [inviteEmailSuccessModal, setInviteEmailSuccessModal] = useState(false);

    const [copiedText, setCopiedText] = useState(false);
    const [inviteEmailModal, setInviteEmailModal] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const email = user.email; // Replace with the recipient's email
    const subject = 'Hello!'; // Replace with your subject
    const body = url; // Replace with the email body content
    const shareUrl = url;

    const copyURL = () => {
        navigator.clipboard.writeText(url);
        setCopiedText(true);
        setTimeout(() => {
            setCopiedText(false);
        }, 1000);
    }
    const houseHoldShare = async () => {
        console.log("===")
        setInviteEmailError({ error: false, text: '' })
        if (emailRef.current.value.length > 0) {
            const user = JSON.parse(localStorage.getItem("user"));
            const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (pattern.test(emailRef.current.value)) {
                const formDataToSend = new FormData();
                formDataToSend.append("email", emailRef.current.value);
                formDataToSend.append("user_id", user?.id);
                formDataToSend.append("link", shareUrl);
                try {
                    const response = await axios.post(
                        `${BASEURL}/api/user/send_email_propagation/`,
                        formDataToSend
                    );
                    if (!response.data.error) {
                        setInviteEmailSuccessModal(true);
                        setInviteEmailModal(false);
                        setTimeout(() => {
                            setInviteEmailSuccessModal(false);
                            // handleClick();
                        }, 4000);
                    } else {
                        setInviteEmailError({ error: true, text: 'Please Enter a valid Email' })
                    }
                } catch (error) {
                    console.log(error)
                }
            } else {
                setInviteEmailError({ error: true, text: 'Please Enter a valid Email' })
            }
        } else {
            setInviteEmailError({ error: true, text: 'Please Enter Your Email' })
        }
    }
    return (
        <>

            <div className="flex-shrink-0 mb-4">
                <h5 className="text-center fs-3">Share & Earn</h5>
            </div>

            <div className="save-share-icon-wrapper flex-shrink-0 mb-5">
                <div className="my-3 copy-bar">
                    <Link onClick={copyURL} to={""} className="text-decoration-none " style={{ wordBreak: 'break-all' }}>
                        <span>{shareUrl}</span>
                        <span className="copy-text">Copy</span>
                    </Link>
                </div>
                {showPlatforms && (
                    <div className="d-flex align-items-center justify-content-center mt-3 gap-3">
                        {/* <button
                            className="notButton "
                            onClick={() => { setInviteEmailModal(true); }}
                        >
                            <img src={SaveShareMixEmailIcon} alt="Close Button" style={{ width: '50px' }} />
                        </button> */}
                        {/* <button
                            className="notButton "
                            onClick={copyURL}
                        >
                            <img src={SaveShareMixLinkIcon} alt="Close Button" style={{ width: '50px' }} />
                        </button> */}
                        {/* <div className="whatsapp-icon position-relative mx-1">
                            <WhatsappShareButton
                                url={shareUrl}
                                quote={'Title or jo bhi aapko likhna ho'}
                                hashtag={'#portfolio...'}
                                beforeOnClick={() => { }}
                            >
                                <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                            <button
                                className="notButton"
                            >
                                <img src={SaveShareMixWhatsappIcon} alt="Close Button" style={{ width: '50px' }} />
                            </button>
                        </div> */}
                        {/* <div className="whatsapp-share-icon position-relative mx-1">
                            <WhatsappShareButton
                                url={shareUrl}
                                quote={''}
                                hashtag={''}
                                beforeOnClick={() => { }}
                            >
                                <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                            <span>Share via Whatsapp</span>
                            <button className="notButton">
                                <img src={SaveShareMixWhatsappIcon} alt="Close Button" style={{ width: '50px' }} />
                            </button>
                        </div> */}
                    </div>
                )}

            </div>

            {/* Copied Modal */}
            <LinkCopiedModal copiedText={copiedText} />

            <Modal
                show={inviteEmailModal}
                centered
                className="soundModal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="text-end">
                        <button
                            className="closeButton notButton ms-auto"
                            onClick={() => { setInviteEmailModal(false); }}
                        >
                            {/* <CrossIcon /> */}
                            <img src={crossIcon} alt="" />
                        </button>
                    </div>
                    <div className="customModalContent mt-4">
                        <h2 className="font-16 lh-16 fw-normal mb-3 ps-3">Email</h2>
                        <div>
                            <input type="email" required ref={emailRef} className="searchBar w-100" />
                            {InviteEmailError.error && <small className="text-danger">{InviteEmailError.text}</small>}
                        </div>
                        <div className="mt-4 d-flex gap-3 justify-content-center mb-4">
                            <button style={{ backgroundColor: '#ffffff1f' }} className='px-5 py-2 fs-6 fw-light text-white text-capitalize btn rounded-pill d-flex align-items-center justify-content-center flex-shrink-0' onClick={houseHoldShare}>
                                <span className=''>Send</span>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={inviteEmailSuccessModal}
                centered
                className="invite-success-modal success-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="p-5 px-3">
                        <h2 className="font-16 lh-16 my-5 fw-normal text-center">Email Sent.</h2>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

const TermOfUseModal = ({ show, close }) => {

    const [_show, setShow] = useState(false);

    useEffect(() => {
        setShow(show);
    }, [show])



    return (
        <>
            <Modal
                show={_show}
                centered
                className="term-of-use-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="d-flex justify-content-between align-items-center ps-3">
                        <button
                            className="closeButton notButton ms-auto p-3"
                            onClick={() => { close(); setShow(false); }}
                        >
                            <img src={crossIcon} alt="" />
                        </button>
                    </div>
                    <div className="term-of-use-modal-wrapper flex-grow-1 overflow-auto">
                        <div className="row">
                            <div className="col-12">
                                <div className="">
                                    <h2 className='text-center mb-5'>Share & Earn Referral Program Terms of Use</h2>
                                    <h4 className='mt-5'>Effective Date: October 1st, 2024</h4>
                                    <p className='lightColor'>
                                        These Terms of Use govern participation in the Share & Earn referral program (“Program”)
                                        offered by Scofa, LLC ("Company", "we", "us", "our"). By participating in the Program, you
                                        (“Referrer”) agree to these Terms.
                                    </p>
                                </div>
                                <div className=''>
                                    <h4 className='mt-5 mb-4'>1. Eligibility</h4>
                                    <p className='lightColor'>
                                        1.1 To participate in the Program, you must:
                                    </p>
                                    <ul className="ps-4">
                                        <li className='lightColor'>
                                            Be at least 18 years old.
                                        </li>
                                        <li className='lightColor'>
                                            Have a valid account with Relax Scofa (Scofa, LLC).
                                        </li>
                                        <li className='lightColor'>
                                            Agree to these Terms of Use.
                                        </li>
                                    </ul>
                                    <p className='lightColor'>
                                        1.2 The Program is intended for individual users. Businesses and affiliates have a separate
                                        program. If you are a business or are interested in becoming an affiliate, please contact us
                                        at <Link className="" to={'http://relaxscofa.com/support'}>support@scofa.com.</Link> to learn more about the business/affiliate referral program, which
                                        offers additional marketing opportunities tailored for your business.
                                    </p>
                                    <h4 className='mt-5 mb-4'>2. Referral Link</h4>
                                    <p className='lightColor'>
                                        2.1 We provide you with a unique referral link, which you may share with others.
                                    </p>
                                    <p className='lightColor'>
                                        2.2 The referred customer (“Referral”) must use the referral link when subscribing to our
                                        services for you to be eligible for a commission.
                                    </p>
                                    <h4 className='mt-5 mb-4'>3. Commissions</h4>
                                    <p className='lightColor'>
                                        3.1 You will earn a commission for each valid subscription that is directly made via your
                                        referral link
                                    </p>
                                    <p className='lightColor'>
                                        3.2 The commission amount will be determined by the Company and is subject to change
                                        at our discretion. Current commission rate is $10 per new yearly subscription.
                                    </p>
                                    <p className='lightColor'>
                                        3.3 Commissions are paid out after a Referral completes their subscription payment and
                                        remains a subscriber for at least 60 days.
                                    </p>
                                    <p className='lightColor'>
                                        3.4 You will not earn commissions on:
                                    </p>
                                    <ul className="ps-4">
                                        <li className='lightColor'>
                                            Referrals who were previously or are already customers of Relax Scofa (Scofa, LLC).
                                        </li>
                                        <li className='lightColor'>
                                            Subscriptions canceled within 60 days from the date of purchase.
                                        </li>
                                        <li className='lightColor'>
                                            Fraudulent activities or violations of these Terms.
                                        </li>
                                    </ul>
                                    <h4 className='mt-5 mb-4'>4. Payouts</h4>
                                    <p className='lightColor'>
                                        4.1 Commission payouts will be made via direct check or gift card.
                                    </p>
                                    <p className='lightColor'>
                                        4.2 Payouts are processed monthly.
                                    </p>
                                    <p className='lightColor'>
                                        4.3 It is your responsibility to ensure your payment details are accurate. We are not
                                        responsible for any delays or missed payments due to incorrect information.
                                    </p>
                                    <h4 className='mt-5 mb-4'>5. Prohibited Activities</h4>
                                    <p className='lightColor'>
                                        5.1 Referrers are prohibited from:
                                    </p>
                                    <ul className="ps-4">
                                        <li className='lightColor'>
                                            Misleading or deceiving others about the Program or the services offered by Relax
                                            Scofa (Scofa, LLC)
                                        </li>
                                        <li className='lightColor'>
                                            Using paid advertising, spamming, or other fraudulent means to distribute referral
                                            links.
                                        </li>
                                        <li className='lightColor'>
                                            Violating any applicable laws or third-party terms (e.g., social media platforms’
                                            terms of use).
                                        </li>
                                    </ul>
                                    <p className='lightColor'>
                                        5.2 We reserve the right to disqualify Referrers from the Program and withhold or reverse
                                        commissions if we suspect fraudulent activity, abuse, or violations of these Terms.
                                    </p>
                                    <h4 className='mt-5 mb-4'>6. Termination</h4>
                                    <p className='lightColor'>
                                        6.1 We reserve the right to modify, suspend, or terminate the Program at any time without
                                        notice.
                                    </p>
                                    <p className='lightColor'>
                                        6.2 We may terminate your participation in the Program at any time if you breach these
                                        Terms.
                                    </p>
                                    <h4 className='mt-5 mb-4'>7. Modifications to the Terms</h4>
                                    <p className='lightColor'>
                                        We reserve the right to update these Terms of Use at any time. Changes will be effective
                                        when posted on our website, and your continued participation in the Program constitutes
                                        your acceptance of any modifications.
                                    </p>
                                    <h4 className='mt-5 mb-4'>8. Limitation of Liability</h4>
                                    <p className='lightColor'>
                                        Relax Scofa (Scofa, LLC) shall not be liable for any indirect, incidental, or consequential
                                        damages arising out of your participation in the Program or any errors in the payout
                                        process.
                                    </p>
                                    <h4 className='mt-5 mb-4'>9. Governing Law</h4>
                                    <p className='lightColor'>
                                        These Terms shall be governed by and construed in accordance with the laws of Citrus
                                        County, State of Florida, without regard to its conflict of law principles.

                                    </p>
                                    <h4 className='mt-5 mb-4'>10. Contact Information</h4>
                                    <p className='lightColor'>
                                        If you have any questions or concerns about these Terms or the Program, please contact us
                                        at <Link className="" to={'http://relaxscofa.com/support'}>support@scofa.com.</Link>
                                    </p>
                                    <p className="my-5"></p>
                                    

                                </div>
                            </div>
                            <div className="col-12 my-3">
                                <p className="my-5"></p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export { DescriptionEnjoyAndEarn, LegalForm, ShareToSocialPlatform, TermOfUseModal }; 