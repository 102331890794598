import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import BASEURL from "../../Config/global";
import { setAccessToken } from "../../Util/authHeader";

import { useDispatch } from "react-redux";
import { setUserDetails } from "../../Store/Slices/UserSlice";

import AuthLayout from "../../Layout/AuthLayout";
import CustomButton from "../../Components/CustomButton";
import CustomInput from "../../Components/CustomInput";

import "./style.css";

const Verification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: false, text: "" });

  const handleClick = async (e) => {
    setLoading(true)
    e.preventDefault();
    const formDataToSend = new FormData();
    const userEmail = localStorage.getItem("user_email");

    formDataToSend.append("email", userEmail?.trim());
    formDataToSend.append("otp", formData.otp);


    try {
      const response = await axios.post(`${BASEURL}/api/user/otp_verify/`, formDataToSend);
      if (response.data.error) {
          setLoading(false);
          setErrorMsg({ error: true, text: response.data.message });
      } else {
        setLoading(false);
        navigate("/reset-password");
      }
    } catch (error) {
      console.log(error)
    }
  };

  return (
    <>
      <AuthLayout pagename="forgot-password">
        {/* <h3 className="lightColor text-center fw-semibold text-uppercase m-0 mb-5">Verification</h3> */}
        <p className="lightColor text-center font-16 lh-16 text-white">
          The reset password code has been <br/> sent to your email
        </p>
        <form>
          <div className="row">
            <div className="col-12 verifyOtp">
              <CustomInput
                placeholder="Enter Code"
                required
                id="otp"
                type="number"
                labelClass="mainLabel"
                inputClass="mainInput text-center"
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    otp: event.target.value,
                  });
                }}
              />
            </div>
            {errorMsg.error == true && (
              <div className="col-12 text-center">
                <p className="smallText lightColor">{errorMsg.text}</p>
              </div>
            )}
          </div>
          <div className="row mt-4 text-center">
            <div className="col-12">
              {
                loading ? (

                  <button className="primaryButton customButton">
                    <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                  </button>
                )
                  : (
                    <CustomButton
                      variant="primaryButton"
                      text="Verify Code"
                      onClick={handleClick}
                    />
                  )
              }
            </div>
          </div>
        </form>
      </AuthLayout>
    </>
  );
};

export default Verification;
