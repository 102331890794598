export const hideUrlForCountry = function (country) {
    switch (country?.toLowerCase()) {
        case 'us':
            console.log("country", country)
            return true
        case 'usa':
            return true
        case 'united states of america':
            return true
        case 'united states':
            return true
        case 'america':
            return true
        case 'u.s.a':
            return true
        default:
            return false
    }
}

export const checkIsCouponUser = () => {
    const paths = ["promo", "promoint", "pedim", "qcm", "jsterling", "daniblifting"]
    let routePath = window.location.pathname?.toLowerCase().split("/")[1]
    let isCouponUser = paths.find(f=>f === routePath)
    return isCouponUser
}