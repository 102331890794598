import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userDetails: null,
    signupResponse:null,
    checkElegibility: true, 
    showPaywallModal:false,
    userPrompts:{}
  },
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setSignUpResponse:(state, action)=>{
      state.signupResponse = action.payload
    }, 
    setCheckElegibility: (state, action) => {
      state.checkElegibility = action.payload
    },
    setShowPaywallModal: (state, action) => {
      state.showPaywallModal = action.payload
    },
    setUserPrompts:(state, action)=>{
      state.userPrompts = action.payload
    }
  },
});

export default userSlice.reducer;
export const { setUserDetails, setSignUpResponse, setCheckElegibility, setShowPaywallModal, setUserPrompts } = userSlice.actions;
