import axios from "axios";
import { useEffect, useRef, useState } from "react";
import MainLayout from "../../Layout/MainLayout";
import BASEURL from "../../Config/global";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch } from "react-redux";
import CustomAudioCard from "../../Components/CustomAudioCard";
import AudioStrip from "../../Components/AudioStrip";
import { userLogout } from "../../Store/Slices/APIs";
import "./style.css";
import Common from "../../Util/common";
import { useLocation } from "react-router";

const FeaturedMixes = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const alwaysOpenStates = useRef(location?.state?.from === "/featured-mixes-single" ? JSON.parse(localStorage.getItem('featured-state')) : ["0"]);

  const [categorizedSoundsData, setCategorizedSoundsData] = useState([]);

  useEffect(() => {
    dispatch(userLogout())
  }, [])

  useEffect(() => {
    if (location?.state?.from === "/featured-mixes-single") {
      alwaysOpenStates.current = JSON.parse(localStorage.getItem('featured-state'));
    } else {
      localStorage.setItem('featured-state', JSON.stringify(alwaysOpenStates.current));
    }
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      // http://backend.dev.relax.scofa.com/api/sounds/mixture-categories/'
      const response = await axios.get(`${BASEURL}/api/sounds/mixture-categories/`);
      if (!response?.data?.error && response?.data?.data?.length > 0) {
        const sortedData = response.data.data.sort(function (a, b) { return a.sequence - b.sequence })
        setCategorizedSoundsData(sortedData);
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleAccordionState = (event, index) => {
    console.clear();
    const array = JSON.parse(localStorage.getItem('featured-state')) || [];
    if (event.target.className === 'accordion-button collapsed') {
      array.push(index.toLocaleString());
      localStorage.setItem('featured-state', JSON.stringify(array));
    } else {
      const indexToRemove = array.findIndex(item => item === index.toLocaleString());
      const result = indexToRemove !== -1 ? array.slice(0, indexToRemove).concat(array.slice(indexToRemove + 1)) : [];
      localStorage.setItem('featured-state', JSON.stringify(result));
    }
  }

  return (
    <>
      <MainLayout title="Featured Mixes">
        <div className="row mb-3">
          <div className="col-12">
            <Accordion
              defaultActiveKey={alwaysOpenStates.current}
              alwaysOpen
              className="customAccordion audio-accordian"
            >
              {categorizedSoundsData && categorizedSoundsData?.map((categorizedData, index) => {
                let sortedChildrens = categorizedData.children_category_data
                if (sortedChildrens > 0) {
                  sortedChildrens = sortedChildrens.sort(function (a, b) { return a.sequence - b.sequence })
                }
                return (
                  <Accordion.Item eventKey={index.toLocaleString()} key={categorizedData.id} >
                    <Accordion.Header
                      style={{
                        backgroundImage: `url(${categorizedData?.banner})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundColor: '#1c3664',
                      }}
                      onClick={(e) => handleAccordionState(e, index)}
                    >
                      {categorizedData?.name}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className={index == 0 ? 'audio-boxes-wrapper' : 'audios-rows audioStrips stripedRows'}>
                        {categorizedData?.category_data?.length > 0  && categorizedData?.category_data?.map((item, idx) => (
                          index == 0 ? <CustomAudioCard key={idx} item={item} type={Common.FEATUREDMIXES} /> : <AudioStrip key={idx} item={item} type={Common.FEATUREDMIXES} />
                        ))}
                      </div>
                      <div>
                        {sortedChildrens?.length > 0  && sortedChildrens?.map((categorizedDataiii, index2) => (
                          <Accordion.Item eventKey={Math.random()} key={index2}>
                            <Accordion.Header
                              className="sub-category-header"
                              style={{
                                backgroundImage: `url(${categorizedDataiii?.banner})`,
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundColor: '#1c3664',
                              }}
                            >
                              {categorizedDataiii?.name}
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="audios-rows audioStrips stripedRows">
                                {categorizedDataiii?.category_data?.length > 0  && categorizedDataiii?.category_data?.map((item, idx) => (
                                  <AudioStrip key={idx} item={item} type={Common.FEATUREDMIXES} />
                                ))}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              })}
            </Accordion>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default FeaturedMixes;