import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAccessToken } from "../Util/authHeader";
import UserRestriction from "../Components/UserRestriction";
import { useDispatch } from "react-redux";
import BASEURL from "../Config/global";
import { setUserPrompts } from "../Store/Slices/UserSlice";
import axios from "axios";

export default function PrivateRoutes({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  let token = getAccessToken();
  useEffect(() => {
    if (!token) {
      navigate("/login", { replace: true });
    }
  }, [token]);

  useEffect(() => {
    fetchData()
  }, [])

  async function fetchData() {
    try {
      let url = `${BASEURL}/api/misc/keys/`;
      const response = await axios.get(url);
      if (!response.data.error) {
        let tranformReponse = {}
        const messages = response.data.data
        for (let index = 0; index < messages.length; index++) {
          const element = messages[index];
          tranformReponse = { ...tranformReponse, ...element }
        }
        dispatch(setUserPrompts(tranformReponse));

      } else {
        console.log(response.data.message);
      }

    } catch (error) {
      console.error(error);
    }
  }
  return (
    <>
      {children}
      <UserRestriction/>
    </>
  );
}
