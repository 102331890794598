import { useEffect, useState } from "react";
import { JSterlingBG, Pedim, PedimBG, PedimMob, QCMBG, QCMMob } from "../../Assets/images";
import Promo from "./Promo";
import CorporatePromo from "./CorporatePromo";
import "./style.css";


const data = {
    "pedim": {
        "text1": "Can’t Sleep? Can’t Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Having Ruminating Thoughts?",
        "text4": "Falling Asleep During The Day?",
        "text5": "We are here to Help!",
        "text6": "Relax & Enjoy a Peaceful Night's Sleep",
        "text7": "Waking up in the middle of the Night? ",
        "text8": "Having Ruminating Thoughts?",
        "text9": "Falling Asleep During The Day?",
        "text10": "Sub-accounts are  part of active Primary accounts",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children Stories", "Sleep Medicine Articles & Guides"],
        "img": Pedim,
        "bg": PedimBG,
        "mobile_img": PedimMob,
        "page_name": "pedim"
    },
    "qcm": {
        "text1": "Can’t Sleep? Can’t Relax?",
        "text2": "Waking up in the middle of the Night?",
        "text3": "Having Ruminating Thoughts?",
        "text4": "Falling Asleep During The Day?",
        "text5": "We are here to Help!",
        "text6": "Relax & Enjoy a Peaceful Night's Sleep",
        "text7": "Waking up in the middle of the Night? ",
        "text8": "Having Ruminating Thoughts?",
        "text9": "Falling Asleep During The Day?",
        "text10": "Sub-accounts are  part of active Primary accounts",
        "list": ["Sounds & Music", "Meditations Breathing", "Guided Adult Stories & Children Stories", "Sleep Medicine Articles & Guides"],
        "img": Pedim,
        "bg": QCMBG,
        "mobile_img": QCMMob,
        "page_name": "qcm"
    }
}

const PromoPush = ({ handleContinueButton }) => {

    const [openedPageData, setOpenedPageData] = useState({});

    useEffect(() => {
        const path = window.location.pathname?.toLowerCase().split("/")[1]
        if (path != "promo") {
            document.querySelector("body")?.classList?.add("overflow-hidden");
        }
        switch (path) {
            case "pedim":
                setOpenedPageData(data.pedim);
                break;
            case "qcm":
                setOpenedPageData(data.qcm);
                break;

            default:
                break;
        }
    }, [])

    return (
        <>
            {
                openedPageData?.page_name === "pedim" || openedPageData?.page_name === "qcm" ? (
                    <CorporatePromo data={openedPageData} handleContinue={() => handleContinueButton()} />
                ) : (
                    <Promo data={openedPageData} handleContinue={() => handleContinueButton()} />
                )
            }
        </>
    );
};

export default PromoPush;
