import React, { useState, useEffect } from 'react'
import { Tick } from "../../Assets/svg";

const CorporatePromo = ({ data, handleContinue }) => {

    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => setHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div className={`promotion-page-wrapper ${data?.page_name}`} style={{
                backgroundImage: `url(${data?.bg})`
            }}>
                <div className="row g-0 align-items-center" style={{ height: "100vh" }}>
                    {/* Plan 2 if bg is not working */}
                    <div className="col-md-5 d-none d-md-block">
                        {/* <img src={data?.img} alt={data?.img} className="img-fluid w-100" /> */}
                    </div>
                    <div className="col-12 col-md-7 mobile-height-add">
                        <div className="content-wrapper" style={{ maxHeight: `${height}px`, overflow: 'auto' }}>
                            <img src={data?.mobile_img} alt={data?.mobile_img} className="mobile-img img-fluid w-100 d-md-none" />
                            <div className="content-wrapper-inner">
                                <h2 className="sub-title">{data?.text1}</h2>
                                <br className='d-none d-md-block' />
                                <h4 className="sub-title-h4 mt-2 mt-md-0">{data?.text2}</h4>
                                <h4 className="sub-title-h4">{data?.text3} <br className="d-md-none" /> {data?.text4}</h4>
                                <br className='d-none d-sm-block' />
                                <h2 className="sub-title my-3 my-sm-0 ">{data?.text5}</h2>
                                <br className='d-none d-sm-block' />
                                <ListItem icon={Tick} data={data?.list || []} />
                                <br className='d-none d-sm-block' />
                                <h2 className="sub-title mt-3 mt-sm-1">{data?.text6}</h2>
                                <h4 className="sub-title-h4 mt-1">{data?.text7}</h4>
                                <h4 className="sub-title-h4">{data?.text8} <br className="d-md-none" /> {data?.text9}</h4>
                                <br />
                                <div className='my-sm-2 my-xl-1'>
                                    <button className="customButton continue-btn" onClick={() => handleContinue()}>Continue</button>
                                </div>
                                <br />
                                <h4 className="sub-title-h4 sub-title-h5 fw-light">{data?.text10}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
const ListItem = ({ data, icon }) => {
    return (
        <ul className="listing-with-icon">
            {data?.map((item, index) => (
                <li className=" " key={index}>
                    <img src={icon} className="tick-style" />
                    <span className="">{item}</span>
                </li>
            ))}
        </ul>
    )
}


export default CorporatePromo