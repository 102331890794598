import React from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';

const ProfileUpdated = ({ show }) => {
    const userPrompts = useSelector((state) => state.user.userPrompts);
    return (
        <>
            <Modal
                show={show}
                centered
                className="copied-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="p-3">
                        <h2 className="font-16 lh-16 m-0 fw-normal text-center py-5 my-5">{userPrompts?.profile_edit_success}</h2>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ProfileUpdated