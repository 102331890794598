import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Modal } from "react-bootstrap";

import {
    clearAllSound,
    removeSound,
    removeAudio,
} from "../../Store/Slices/SoundPlayerSlice";

import {
    // MixerButton,
    // TimerButton,
    PauseButton,
    PlayButton,
    // Spinner,
    CrossIcon,
    crossIcon,
    // playIcon,
    playButton,
    pauseButton,
    saveMixButton,
    timerButton,
    ShareIcon

    // mixerIcon,
    // timerIcon,
    // saveMixIcon,
} from "../../Assets/svg";
import BASEURL from "../../Config/global";

import { Howl, Howler } from "howler";
import CustomButton from "../CustomButton";
import { getAccessToken } from "../../Util/authHeader";
import { useLocation } from "react-router";
import {
    pauseMixer,
    playMixer,
    resetMixer,
} from "../../Store/Slices/MixerSlice";
import VolumeBar from "../VolumeBar";
import VolumeSlider from "./../VolumeSlider";
import { func } from "prop-types";


import ReactAudioPlayer from 'react-audio-player';

import ReactPlayer from 'react-player';


const TestMultiAudioPlayer = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const menuClass = windowWidth < 1024 ? "mobileMenu" : "desktopMenu";

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const location = useLocation();

    const sounds = useSelector((state) => state.soundPlayer.sounds);

    const audio = useSelector((state) => state.soundPlayer.audio);

    const isPlaying = useSelector((state) => state.mixer.play);

    const dispatch = useDispatch();

    const audioPlayer = useRef();

    const stats = { play: false };


    const [soundList, setSoundList] = useState([]);
    const [mixerTimer, setMixerTimer] = useState(null);

    const [showTimerModal, setShowTimerModal] = useState(false);

    const [openTray, setOpenTray] = useState(false);

    const [isPlayingSound, setIsPlayingSound] = useState(false);
    const [isSounds, setIsSounds] = useState([]);
    const [isSoundsCounter, setIsSoundsCounter] = useState(0);
    const [isAudio, setIsAudio] = useState({});


    const [play, setPlay] = useState(true);

    const [currentAudioElem, setCurrentAudioElem] = useState(true);

    const [lastSource, setLastSource] = useState(null);
    const [soundInfo, setSoundInfo] = useState(false);
    
    const [ref, setRef] = useState(null);

    const appendReactPlayer = [];
    const appendReactPlayerD = [];
    const el = [];
    const elD = [];
    let audRef = null;

    const zero = 0;
    const inputElement = React.useRef()

    useEffect(() => {
        const token = getAccessToken();
        if (!token) {
            handleClearMix();
        }
    }, [location]);

    useEffect(() => {
        setIsPlayingSound(false);
        setIsSounds(sounds);
    }, [sounds])

    useEffect(() => {
        setIsAudio(audio);
    }, [audio])

    useEffect(() => {
        const lastElem = sounds[sounds.length - 1];
        setLastSource(BASEURL + lastElem?.ios_patch);
        setSoundInfo(lastElem);
    }, [sounds]);


    useEffect(() => {
        setIsSounds(sounds)
        if (location.pathname == "/home-howl") {
            if (sounds.length > 0) {
                const howl = new Howl({
                    src: [BASEURL + sounds[sounds.length - 1].ios_patch],
                    loop: true,
                    autoplay: false,
                    webAudio: true, // Use Web Audio API if supported
                    html5: true, // Use HTML5 audio if supported
                    autoUnlock: true,
                    preload: true,
                    volume: 0.5,
                    autoSuspend: false,
                });
                howl.play();
            }
        }
    }, [sounds])

    useEffect(() => {
        setIsAudio(audio)
        if (location.pathname == "/home-howl") {
            // console.log(audio)
            if (audio.hasOwnProperty('audio')) {
                const howl = new Howl({
                    src: [BASEURL + audio.audio],
                    loop: true,
                    autoplay: false,
                    webAudio: true, // Use Web Audio API if supported
                    html5: true, // Use HTML5 audio if supported
                    autoUnlock: true,
                    preload: true,
                    volume: 0.5,
                    autoSuspend: false,
                });
                howl.play();
            }
        }
    }, [audio])


    if (sounds.length > 0) {

        const listen = (e, index) => {
            if (e > el[index].audioEl.current.duration - 1) {
                elD[index].audioEl.current.currentTime = 1;
                elD[index].audioEl.current.play();
                el[index].audioEl.current.pause();
                el[index].audioEl.current.currentTime = 1;
            }
        }

        const listenD = (e, index) => {
            if (e > elD[index].audioEl.current.duration - 1) {
                el[index].audioEl.current.play();
                elD[index].audioEl.current.pause();
                elD[index].audioEl.current.currentTime = 1;
            }
        }

        const isReadyToPlay = (e, index) => {
            if (el[index] && !el[index].hasOwnProperty('isStart')) {
                el[index].isStart = true;
                // document.getElementById("myCheck").click()
                // console.log("---")
            }
        }

        appendReactPlayer.push(
            sounds.map((item, index) => {
                return (
                    <div key={index} className="d-none">
                        {/* <button ref={inputElement} onClick={clickme}>abcd</button> */}
                        <ReactAudioPlayer
                            ref={(r) => { el[index] = r; }}
                            src={BASEURL + item.ios_patch}
                            controls
                            autoPlay={false}
                            loop={true}
                            onListen={(e) => listen(e, index)}
                            listenInterval={10}
                            onCanPlay={e => isReadyToPlay(e, index)}
                        />
                        <ReactAudioPlayer
                            ref={(r) => { elD[index] = r; }}
                            src={BASEURL + item.ios_patch}
                            controls
                            autoPlay={false}
                            loop={true}
                            onListen={(e) => listenD(e, index)}
                            listenInterval={10}
                        />
                    </div>
                )
            })
        )

    }

    const handlePlayAll = (e) => {
        sounds.forEach((e, i) => {
            el[i].audioEl.current.play();
        });
        // audRef.audioEl.current.play();
        setIsPlayingSound(true)
    }

    const handlePauseAll = (e) => {
        sounds.forEach((e, i) => {
            el[i].audioEl.current.pause();
            elD[i].audioEl.current.pause();
        });
        // audRef.audioEl.current.pause();
        setIsPlayingSound(false)
    }

    const runTimer = (minutes) => {
        console.log(minutes);
        if (minutes === null) {
            clearTimeout(mixerTimer);
            return;
        } else {
            const milliSeconds = minutes * 60 * 1000;
            clearTimeout(mixerTimer);
            const timeOut = setTimeout(() => {
                handleClearMix();
            }, milliSeconds);
            setMixerTimer(timeOut);
        }
        handleCloseTimer();
    };

    const handleCloseTimer = () => setShowTimerModal(false);
    const handleShowTimer = () => setShowTimerModal(true);

    const handleClearMix = () => {
        dispatch(clearAllSound());
        setOpenTray(false);
        document.body.classList.remove('overflow-hidden');
    };

    const handleRemoveSound = (i) => {
        dispatch(removeSound(i));
    };

    const handleRemoveAudio = () => {
        
        setIsSounds(removeAudio());
    };

    const clickMe = () => {
        const howl = new Howl({
            src: [BASEURL + sounds[0].ios_patch],
            loop: false,
            autoplay: isPlaying,
            webAudio: true, // Use Web Audio API if supported
            html5: true, // Use HTML5 audio if supported
            autoUnlock: true,
            preload: true,
            volume: 0.5,
            autoSuspend: false,
        });
        console.log(howl.play())
    }

    return (
        <>
            {(sounds.length > 0 || isAudio?.audio) && (
                <div className={`audioPlayerWrapper ${menuClass}`}>
                    <div className="row">
                        <div className="col-12">
                            {/* <button id="myCheck" onClick={clickMe}>abc</button> */}
                            {/* {appendReactPlayer} */}
                            <div className="audioPlayerControls">

                                {isPlayingSound ? (
                                    <button
                                        className="playButton playerAction"
                                        onClick={handlePauseAll}
                                    >
                                        <img
                                            src={pauseButton}
                                            alt="Pause"
                                            className="playerActionIcon"
                                        />
                                    </button>
                                ) : (
                                    <button
                                        className="playButton playerAction"
                                        onClick={handlePlayAll}
                                    >
                                        <img
                                            src={playButton}
                                            alt="Play"
                                            className="playerActionIcon"
                                        />
                                    </button>
                                )}


                                <div className="mixerCenter d-md-block d-none flex-grow-1">
                                    <p className="overallVolumeText">Volume</p>
                                    <VolumeSlider />
                                </div>


                                <div className="mixerRight">

                                    <button className="playerAction">
                                        <img
                                            src={timerButton}
                                            alt="Timer"
                                            className="playerActionIcon"
                                            onClick={handleShowTimer}
                                        />
                                    </button>

                                    <div
                                        className="currentMixButton"
                                        onClick={() => {
                                            document.body.classList.add('overflow-hidden');
                                            setOpenTray(!openTray);
                                        }}
                                    >
                                        <div className="currentMixIcons">
                                            {isSounds.length > 0 && (
                                                <div className="currentImageWrapper">
                                                    <img
                                                        src={BASEURL + isSounds[0].thumbnail}
                                                        alt="Thumbnail"
                                                    />
                                                </div>
                                            )}
                                            {isAudio?.audio && (
                                                <div className="currentImageWrapper">
                                                    <img
                                                        src={BASEURL + isAudio.thumbnail}
                                                        alt="Thumbnail"
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className={`currentMixContent ${(!isAudio?.audio && soundList.length) ||
                                                (isAudio?.audio && soundList.length < 1)
                                                ? "ms-1"
                                                : "ms-1"
                                                }`}
                                        >
                                            <p className="currentMixTitle">Current Mix</p>
                                            <p className="currentMixText">
                                                {isSounds.length + (isAudio?.audio ? 1 : 0)} Item
                                                {isSounds.length + (isAudio?.audio ? 1 : 0) > 1 &&
                                                    "s"}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* <div className="d-md-none">
                                {
                                    isSounds.map((item, i) => {
                                        return (
                                            <div key={i} className="react-audio-player">

                                                <ReactAudioPlayer
                                                    ref={(el) => { ref[i] = el }}
                                                    src={BASEURL + item.ios_patch}
                                                    controls
                                                    autoPlay={false}
                                                    loop={true}
                                                    // onListen={(e) => listen(e, i)}
                                                    // onLoadedMetadata={handleLoadMetadata}
                                                    // listenInterval={10}
                                                    // onPlay={handlePlay}
                                                />

                                            </div>
                                        )
                                    })
                                }

                            </div> */}

                            <div className="d-none">
                                {/* <ReactAudioPlayer
                                    ref={(el) => { audRef = el }}
                                    src={BASEURL + isAudio.audio}
                                    controls
                                    loop={false}
                                    onSeeked={(e) => console.log(e)}
                                // onListen={(e) => listen(e, i)}
                                // onLoadedMetadata={handleLoadMetadata}
                                // listenInterval
                                /> */}
                            </div>

                        </div>
                    </div>
                </div>
            )}


            <Modal
                show={showTimerModal}
                centered
                onHide={handleCloseTimer}
                className="soundModal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="text-end">
                        <button
                            className="closeButton notButton ms-auto"
                            onClick={handleCloseTimer}
                        >
                            {/* <CrossIcon /> */}
                            <img src={crossIcon} alt="" />
                        </button>
                    </div>
                    <div className="customModalContent text-center">
                        <h2 className="modalHeading pageTitle mb-4">Select Time</h2>
                        <div className="timerOptions">
                            <CustomButton
                                variant="secondaryButton fw-light"
                                text="None"
                                onClick={() => {
                                    runTimer(null);
                                }}
                            />
                            <CustomButton
                                variant="secondaryButton fw-light"
                                text="1 Minute"
                                onClick={() => {
                                    runTimer(1);
                                }}
                            />
                            <CustomButton
                                variant="secondaryButton fw-light"
                                text="15 Minutes"
                                onClick={() => {
                                    runTimer(15);
                                }}
                            />
                            <CustomButton
                                variant="secondaryButton fw-light"
                                text="30 Minutes"
                                onClick={() => {
                                    runTimer(30);
                                }}
                            />
                            <CustomButton
                                variant="secondaryButton fw-light"
                                text="1 Hour"
                                onClick={() => {
                                    runTimer(60);
                                }}
                            />
                            <CustomButton
                                variant="secondaryButton fw-light"
                                text="2 Hours"
                                onClick={() => {
                                    runTimer(120);
                                }}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div className={`mixerTray ${openTray ? "open" : "close"}`}>

                <div className="mixerHeader p-4 p-sm-3">
                    <h3>Mixer</h3>
                    <div className="text-end d-flex align-items-center">
                        <button className="notButton me-3 d-flex align-items-center">
                            <img
                                src={ShareIcon}
                                alt="Share Icon"
                                height="13"
                            />
                        </button>
                        <button
                            className="notButton d-flex align-items-center"
                            onClick={() => {
                                document.body.classList.remove('overflow-hidden');
                                setOpenTray(false);
                            }}
                        >
                            <img src={crossIcon} alt="Close Button" />
                        </button>
                    </div>
                </div>

                {isAudio?.audio && (
                    <>
                        <div className="audioHeader">
                            <p>{isAudio.type} (1/1)</p>
                        </div>
                        <div className="otherAudioWrapper">
                            <div className="individualAudio">
                                <div className="mixerSoundDetail">
                                    <div className="mixerSoundThumbnailWrapper flex-shrink-0">

                                        <div className="mixerAudioThumbnail">
                                            <img
                                                src={BASEURL + isAudio.thumbnail}
                                                alt="Thumbnail"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <p className="mixerAudioTitle">
                                            {isAudio.title}
                                        </p>
                                    </div>
                                    <div className="d-flex align-center gap-2 flex-shrink-0">
                                        <span
                                            className="soundControlButton"
                                            onClick={handleRemoveAudio}
                                        >
                                            <img src={crossIcon} alt="Cross Icon" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {isSounds.length > 0 && (
                    <>
                        <div className="audioHeader p-4 p-sm-2" style={{ borderBottom: "2px solid #ffffff38" }}>
                            <p className="d-none d-sm-block">Sounds</p>
                            <p className="d-sm-none">+ Add Sounds ({isSounds.length + (isAudio?.audio ? 1 : 0)}/5)</p>
                        </div>
                        <div className="individualSoundsWrapper">
                            {isSounds.map((sound, index) => (
                                <div className="individualAudio" key={index}>
                                    <div className="mixerSoundDetail">
                                        <div className="mixerSoundThumbnailWrapper flex-shrink-0">
                                            {/* <span
                                                className="soundControlButton audioRemoveButton"
                                                onClick={() => {
                                                    handleRemoveSound(index);
                                                }}
                                            >
                                                <img src={crossIcon} alt="Cross Icon" />
                                            </span> */}

                                            <div className="mixerSoundThumbnail">
                                                <img
                                                    src={BASEURL + sound.thumbnail}
                                                    alt="Thumbnail"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <p className="mixerAudioTitle">
                                                {sound.title}
                                            </p>
                                        </div>
                                        <div className="d-flex align-center gap-2 flex-shrink-0">
                                            {/* <span
                                                className="soundControlButton"
                                                onClick={() =>
                                                    handleSoundVolume("Decrease", index)
                                                }
                                            >
                                                -
                                            </span>
                                            <span
                                                className="soundControlButton"
                                                onClick={() =>
                                                    handleSoundVolume("Increase", index)
                                                }
                                            >
                                                +
                                            </span> */}
                                            <span
                                                className="soundControlButton"
                                                onClick={() => {
                                                    handleRemoveSound(index);
                                                }}
                                            >
                                                <img src={crossIcon} alt="Cross Icon" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}

                <div className="clearMixWrapper mt-3">
                    <button className="clearMixButton" onClick={handleClearMix}>
                        Clear Mix
                    </button>
                </div>

                <div className="mixer-footer d-sm-none">
                    <div className="d-flex justify-content-between align-items-center px-5 w-100">

                        {/* Timer */}
                        <div className="flex-shrink-0">
                            <button className="playerAction">
                                <img
                                    src={timerButton}
                                    alt="Timer"
                                    className="playerActionIcon"
                                    onClick={handleShowTimer}
                                />
                                <p className="playerActionText mt-2">Add Timer</p>
                            </button>
                        </div>

                        {/* play pause */}
                        <div className="flex-shrink-0">
                            {isPlayingSound ? (
                                <button
                                    className="playButton playerAction"
                                    onClick={handlePauseAll}
                                >
                                    <img
                                        src={pauseButton}
                                        alt="Pause"
                                        className="playerActionIcon"
                                    />
                                </button>
                            ) : (
                                <button
                                    className="playButton playerAction"
                                    onClick={handlePlayAll}
                                >
                                    <img
                                        src={playButton}
                                        alt="Play"
                                        className="playerActionIcon"
                                    />
                                </button>
                            )}
                        </div>

                        {/* Save Mix */}
                        <div className="flex-shrink-0">
                            <button className="playerAction">
                                <img
                                    src={timerButton}
                                    alt="Timer"
                                    className="playerActionIcon opacity-0"
                                // onClick={handleShowTimer}
                                />
                                <p className="playerActionText mt-2">Save Mix</p>
                            </button>
                        </div>

                    </div>
                </div>

            </div>


        </>
    );
};
export default TestMultiAudioPlayer;
