import React from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux';

const PaymentDeclined = ({ paymentDecline }) => {
    const userPrompts = useSelector((state) => state.user.userPrompts);

    return (
        <>
            <Modal
                show={paymentDecline}
                centered
                className="success-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="text-end ml-auto">
                        {/* <button
                            className="closeButton notButton ms-auto"
                            onClick={() => setPaymentDecline(false)}
                        >
                            <img src={crossIcon} alt="" />
                        </button> */}
                    </div>
                    <div className="p-5">
                        <h2 className="font-16 lh-16 my-5 fw-normal text-center">{userPrompts?.payment_decline_message}</h2>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PaymentDeclined